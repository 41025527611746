import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQ = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div>
      {data.map((item) => (
        <div className="row mt-2" key={item.question}>
          <Accordion>
            <AccordionSummary
              style={{ textAlign: "left" }}
              expandIcon={<ExpandMoreIcon />}
            >
              <b> {t(item.question)}</b>
            </AccordionSummary>
            <AccordionDetails style={{ textAlign: "left" }}>
              {t(item.answer)}
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
