import React, { useEffect, useState } from "react";
import "./QR.scss";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../Context";
import { CircularProgress } from "@mui/material";
import QRCode from "qrcode";
import { useParams } from "react-router-dom";
import { ReactComponent as DummyQR } from "./qr-code.svg";
import { ReactComponent as IconRefresh } from "./icon-refresh.svg";
import { ReactComponent as IconError } from "./error-905.svg";
import { t } from "i18next";
import Share from "./Share";

const QR_BACKEND_STATES = {
  0: "loading",
  1: "idle",
  2: "connected",
};

const generateQR = async (text) => {
  try {
    return await QRCode.toDataURL(text);
  } catch (err) {
    console.error(err);
  }
};

export function QRError() {
  return (
    <>
      <p className="QRState_refreshText" style={{ visibility: "hidden" }}>
        -----
      </p>
      <div className="QR_image p-3">
        <DummyQR />
        <div className="QR_expireBtn">
          <IconError color="red" />
        </div>
      </div>
      <div className="mt-5">
        <h3>{t("invalidRequestTitle")}</h3>
        <div className="QRState_text">{t("invalidRequestDescription")}</div>
      </div>
    </>
  );
}

function QR() {
  const { qrData, connection, setConnection, config } = useAppContext();
  const [refreshing, setRefreshing] = useState(true);
  const [qrState, setQrState] = useState("loading");
  const [count, setCount] = useState(60);
  const [qrImage, setQrImage] = useState();
  let { id } = useParams();
  const { t } = useTranslation();

  async function callRefresh(id, onSuccess, apiURL) {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `${apiURL}/api/wa/session/${id}`);
      xhr.onload = () => {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          if (onSuccess) {
            onSuccess(data.session.status);
          }
        } else {
          setQrState("invalid");
          console.error(`Error: ${xhr.status}`);
        }
      };
      xhr.send();
    } catch (error) {
      console.error(error);
      setRefreshing(false);
    }
  }

  useEffect(() => {
    if (config.api) {
      callRefresh(
        id,
        (status) => {
          setQrState(QR_BACKEND_STATES[status]);
        },
        config.api
      );
      setRefreshing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useEffect(() => {
    if (qrData?.qrCode) {
      const generatedCode = async () => {
        return await generateQR(qrData?.qrCode);
      };

      generatedCode().then((r) => setQrImage(r));
      const elapsedTime =
        Math.floor(parseInt(new Date().getTime() / 1000) + 1) - qrData.time;

      const countDown = qrData?.isFirstQr ? 60 : 20;

      setCount(countDown - elapsedTime);

      setConnection({ status: null });
      setQrState("waiting");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrData]);

  useEffect(() => {
    if (connection?.status === "disconnected") {
      setQrState("expired");
    }
    if (connection?.status === "connected") {
      setQrState("connected");
    }
  }, [connection]);

  useEffect(() => {
    if (qrState === "waiting") {
      const timer = setTimeout(() => {
        let countDown = count - 1;
        countDown = countDown <= 0 ? 0 : countDown;
        setCount(countDown);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [count, qrState]);

  function QRState({ state }) {
    return {
      invalid: <QRError />,
      loading: (
        <>
          <p className="QRState_refreshText" style={{ visibility: "hidden" }}>
            -----
          </p>
          <div className="QR_image p-3">
            <DummyQR />
            <div className="QR_progress">
              <CircularProgress color="info" size={30} />
            </div>
          </div>
          <div className="QRState mt-5">
            <div className="QRState_dot blink-bg" />
            <div className="QRState_text">{t("qr.stateLoading")}</div>
          </div>
        </>
      ),
      waiting: (
        <>
          {qrData.time && (
            <p className="QRState_refreshText">
              {t("qr.willRefresh", { count })}
            </p>
          )}
          <div className="QR_image">
            <img width={300} height={300} src={qrImage} alt="QR CODE" />
          </div>
          <div className="QRState mt-5">
            <div className="QRState_dot blink-bg" />
            <div className="QRState_text">{t("qr.stateWaiting")}</div>
          </div>
        </>
      ),
      expired: (
        <>
          <button
            className="QR_refreshBtn"
            onClick={() => {
              setQrState("loading");
              callRefresh(id, () => {}, config.api);
            }}
          >
            <p className="QRState_refreshText" style={{ visibility: "hidden" }}>
              -----
            </p>
            <div className="QR_image">
              <DummyQR />
              <div className="QR_expireBtn">
                <IconRefresh />
              </div>
            </div>
          </button>
          <div className="QRState mt-5">
            <div className="QRState_dot QRState_dot-red" />
            <div className="QRState_text">{t("qr.stateExpired")}</div>
          </div>
        </>
      ),
      connected: (
        <div>
          <p className="QR_successText mt-5 mb-5">{t("qr.successText")}</p>
          <div className="QRState mt-5">
            <div className="QRState_dot" />
            <div className="QRState_text">{t("qr.stateConnected")}</div>
          </div>
        </div>
      ),
    }[state];
  }

  return (
    <>
      {refreshing ? (
        <div
          className="row d-flex justify-content-center align-content-center"
          style={{ height: "540px" }}
        >
          <div className="col">
            <CircularProgress />
          </div>
        </div>
      ) : (
        <>
          {qrState !== "invalid" && (
            <Share url={`${config.url}/wa/${id}`} qrState={qrState} />
          )}
          <div className="row">
            <div className="col-12 mt-2">
              <div className="QR d-flex justify-content-center flex-column align-items-center">
                <QRState state={qrState} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default QR;
