import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAppContext } from "../Context";
import { useTranslation } from "react-i18next";

function LanguagePicker() {
  const { language, setLanguage } = useAppContext();
  const { i18n } = useTranslation();

  const handleChange = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">Language</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={language}
        label="Language"
        onChange={handleChange}
      >
        <MenuItem value="en">🇬🇧 English</MenuItem>
        <MenuItem value="ar">🇸🇦 عربي</MenuItem>
        <MenuItem value="de">🇩🇪 Deutsch</MenuItem>
        <MenuItem value="it">🇮🇹 Italiano</MenuItem>
        <MenuItem value="es">🇪🇸 Español</MenuItem>
        <MenuItem value="ja">🇯🇵 Japanese</MenuItem>
        <MenuItem value="nl">🇳🇱 Nederlands</MenuItem>
        <MenuItem value="pt">🇵🇹 Português</MenuItem>
        <MenuItem value="fr">🇫🇷 Français</MenuItem>
        <MenuItem value="ru">🇷🇺 Русский</MenuItem>
        <MenuItem value="he">🇮🇱 עִברִית</MenuItem>
        <MenuItem value="zh">🇨🇳 中国人</MenuItem>
        <MenuItem value="tr">🇹🇷 Türkçe</MenuItem>
        <MenuItem value="ro">🇷🇴 Română</MenuItem>
        <MenuItem value="hu">🇭🇺 Magyar</MenuItem>
        <MenuItem value="uk">🇺🇦 українська</MenuItem>
        <MenuItem value="hi">🇮🇳 हिंदी</MenuItem>
        <MenuItem value="ko">🇰🇷 한국인</MenuItem>
        <MenuItem value="sv">🇸🇪 Svenska</MenuItem>
        <MenuItem value="vi">🇻🇳 Việt</MenuItem>
        <MenuItem value="id">🇮🇩 Indonesia</MenuItem>
      </Select>
    </FormControl>
  );
}

export default LanguagePicker;
