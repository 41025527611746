import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import { AppContextProvider } from "./ContextProvider";
import "./i18n";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import { QRError } from "./Components/QR";

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  return (
    <div className="App" style={{ flex: 1 }}>
      <main>
        <div className="container">
          <div className="row">
            <div className="col ">
              <QRError />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<ErrorBoundary />}>
      <Route
        element={<App />}
        path="/wa/:id"
        errorElement={<ErrorBoundary />}
      />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AppContextProvider>
      <RouterProvider router={router} />
    </AppContextProvider>
  </React.StrictMode>
);
