import React, { useEffect, useState } from "react";
import { AppContext } from "./Context";
import { getI18n, useTranslation } from "react-i18next";

export const AppContextProvider = ({ children }) => {
  const [language, setLanguage] = useState(getI18n().language.split("-")[0]);
  const [isRtl, setIsRtl] = useState(false);
  const [appName, setAppName] = useState("");
  const [qrData, setQrData] = useState("");
  const [connection, setConnection] = useState("");
  const [config, setConfig] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const rtl = getI18n().dir() === "rtl";
    setIsRtl(rtl);
    document
      .getElementsByTagName("html")[0]
      .setAttribute("dir", rtl ? "rtl" : "ltr");
  }, [t]);

  return (
    <AppContext.Provider
      value={{
        language,
        setLanguage,
        qrData,
        connection,
        config,
        isRtl,
        setIsRtl,
        setConfig,
        setConnection,
        setQrData,
        appName,
        setAppName,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
