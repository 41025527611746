import { useEffect } from "react";
import { useAppContext } from "./Context";
import Pusher from "pusher-js";

export function InitializePusher({ id }) {
  const { setQrData, setConnection } = useAppContext();

  useEffect(() => {
    const pusher = new Pusher("58297b18c314cb4aa1dc", {
      cluster: "eu",
      encrypted: true,
    });
    Pusher.logToConsole = false;
    const channel = pusher.subscribe(id);
    channel.bind("qr-refreshed", (data) => {
      setQrData(data);
    });

    channel.bind("connection", (data) => {
      setConnection(data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
}
