import React from "react";

export const defaultContextApp = {
  language: "en",
  appName: "",
  qrData: "",
  connection: "",
  config: {},
  isRtl: null,
  setIsRtl: () => {},
  setConfig: () => {},
  setConnection: () => {},
  setQrData: () => {},
  setAppName: () => {},
  setLanguage: () => {},
};

export const AppContext = React.createContext(defaultContextApp);

export const useAppContext = () => {
  return React.useContext(AppContext);
};
