import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import LanguagePicker from "./Components/LanguagePicker";
import { useAppContext } from "./Context";

function ResponsiveAppBar({ logo }) {
  const { isRtl } = useAppContext();

  return (
    <AppBar position="static" style={{ paddingTop: 10, paddingBottom: 10 }}>
      <div className="container">
        <Container maxWidth="xl" dir={isRtl ? "rtl" : "ltr"}>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }} style={{ maxWidth: "150px" }}>
              {logo}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LanguagePicker />
            </Box>
          </Toolbar>
        </Container>
      </div>
    </AppBar>
  );
}
export default ResponsiveAppBar;
