import React, { useEffect, useRef } from "react";
import "./Share.scss";
import {
  EmailIcon,
  EmailShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TelegramIcon,
  TelegramShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard/src";

function SocialShare({ url }) {
  const [copied, setCopied] = React.useState(false);

  const ref = useRef(null);

  const onCopy = React.useCallback(() => {
    setCopied(true);
  }, []);

  function handleFocus() {
    document.querySelector("#select-input").select();
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  const { t } = useTranslation();

  return (
    <>
      <div className="col-12 d-flex justify-content-center mt-3">
        <div className="CopyInput">
          <CopyToClipboard onCopy={onCopy} text={url}>
            <input
              id="select-input"
              ref={ref}
              value={url}
              onFocus={handleFocus}
              className="CopyInput_input"
            />
          </CopyToClipboard>
          <div className="CopyInput_button">
            <CopyToClipboard onCopy={onCopy} text={url}>
              <button>
                <span>{copied ? t("buttonCopySuccess") : t("buttonCopy")}</span>
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </>
  );
}

function Share({ url, qrState }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="row mt-5 mb-3">
        <div className="col">
          <h2>
            {t("qr.qrUrl")}
            <div className="row">
              <div className="col">
                <SocialShare url={url} />
              </div>
            </div>
          </h2>
        </div>
      </div>
      {qrState !== "connected" && (
        <div className="Share row d-flex justify-content-center">
          <div className="col" style={{ maxWidth: "375px" }}>
            <div className="row">
              <div className="col">
                <WhatsappShareButton url={url}>
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
              </div>
              <div className="col">
                <EmailShareButton url={url}>
                  <EmailIcon size={40} round={true} />
                </EmailShareButton>
              </div>
              <div className="col">
                <VKShareButton url={url}>
                  <VKIcon size={40} round={true} />
                </VKShareButton>
              </div>
              <div className="col">
                <TelegramShareButton url={url}>
                  <TelegramIcon size={40} round={true} />
                </TelegramShareButton>
              </div>
              <div className="col">
                <FacebookMessengerShareButton url={url}>
                  <FacebookMessengerIcon size={40} round={true} />
                </FacebookMessengerShareButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Share;
